
<template>
<div>
  <CRow>
    <CCol col="12">
    
    </CCol>
  </CRow>
  <CRow>
    <CCol md="12">
      <CCard accent-color="danger">
        <CCardHeader :color="$theme.backColor">
          <div class="default-card-header">
            <CIcon name="cil-home"> </CIcon>
            Branches
          </div>
        </CCardHeader>

        <CCardBody>
          <CRow>
            <CCol v-for="branch of branchList" :key="branch.id" md="6" xs="12">
              <BranchCard
                :branch-info="branch"
                :map="true"
                @click="handleBranchClick(branch)"
              />
            </CCol>
          </CRow>
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
  </div>
</template>


<script>
import BranchCard from "@/components/branches/BranchCard";
import CBranchSelect from "@/components/iotcore/CBranchSelect"

export default {
  name: "BranchViewer",
  components: {
    BranchCard,
    CBranchSelect,
  },
  data() {
    return {};
  },
  computed: {
    branchList() {
      if(this.$store.getters.branches)
      return this.$store.getters.branches.map(item=>{
        return {...item, img: this.getBranchImg(item)}
      })
    },
  },
  mounted() {},
  methods: {
    getBranchImg(branchInfo) {
      let id;
      try {
        
        id = branchInfo ? branchInfo.metadata.code : "";
        id = id.toLowerCase();
        if (!id) {
          return "";
        }
      } catch (err) {
        console.log(err);
        return "";
      }
      return `https://i2.wp.com/cdn.auth0.com/avatars/${id}.png?ssl=1`;
    },
    async handleBranchClick(branch) {
      try {
        console.log("Clicked");
        let branch_id = branch.branch_id;

        this.$router.push(`branches/${branch_id}`);
      } catch (err) {
        console.log(err);
      }
    },
  },
};
</script>
