var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("CRow", [_c("CCol", { attrs: { col: "12" } })], 1),
      _c(
        "CRow",
        [
          _c(
            "CCol",
            { attrs: { md: "12" } },
            [
              _c(
                "CCard",
                { attrs: { "accent-color": "danger" } },
                [
                  _c(
                    "CCardHeader",
                    { attrs: { color: _vm.$theme.backColor } },
                    [
                      _c(
                        "div",
                        { staticClass: "default-card-header" },
                        [
                          _c("CIcon", { attrs: { name: "cil-home" } }),
                          _vm._v(" Branches ")
                        ],
                        1
                      )
                    ]
                  ),
                  _c(
                    "CCardBody",
                    [
                      _c(
                        "CRow",
                        _vm._l(_vm.branchList, function(branch) {
                          return _c(
                            "CCol",
                            { key: branch.id, attrs: { md: "6", xs: "12" } },
                            [
                              _c("BranchCard", {
                                attrs: { "branch-info": branch, map: true },
                                on: {
                                  click: function($event) {
                                    return _vm.handleBranchClick(branch)
                                  }
                                }
                              })
                            ],
                            1
                          )
                        }),
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }